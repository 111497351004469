<template>
  <div id="app">
    <LoginForm v-if="!loggedIn" @loginSuccess="handleLoginSuccess" />
    <el-container v-else>
      <el-header>
        <h1>服务器管理</h1>
      </el-header>
      <el-main>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="12" :md="8" v-for="server in servers" :key="server.id">
            <el-card>
              <div>{{ server.name }}</div>
              <el-button type="primary" @click="goToServer(server.url)">进入宝塔</el-button>
              <el-button type="info" @click="showDetails(server)">详细信息</el-button>
            </el-card>
          </el-col>
        </el-row>
      </el-main>
    </el-container>

    <el-dialog v-model="dialogVisible" title="服务器详细信息">
      <p>服务器名称: {{ currentServer.name }}</p>
      <p>厂商网址: <a :href="currentServer.vendorUrl" target="_blank">{{ currentServer.vendorUrl }}</a></p>
      <p>宝塔: <a :href="currentServer.url" target="_blank">{{ currentServer.url }}</a></p>
      <p>到期时间:{{ currentServer.expiryDate }}</p>
      <p>IP 地址: {{ currentServer.ip }}</p>
      <p>域名: </p>  
      <ul>
    <li v-for="domain in currentServer.domain" :key="domain">
      <a :href="domain" target="_blank">{{ domain }}</a>
    </li>
  </ul>
    
  
    

      
      <template #footer>
        <el-button @click="dialogVisible = false">关闭</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import LoginForm from './LoginForm.vue';

export default {
  name: 'App',
  components: {
    LoginForm
  },
  data() {
    return {
      servers: [],
      dialogVisible: false,
      currentServer: {},
      loggedIn: false
    };
  },
  watch: {
    loggedIn(newVal) {
      if (newVal) {
        this.fetchServers();
      }
    }
  },
  methods: {
    fetchServers() {
      axios.get('https://www.qwq.vin/api/server/getserver.php')
        .then(response => {
          this.servers = response.data;
        })
        .catch(error => {
          console.error('Error fetching servers:', error);
        });
    },
    goToServer(url) {
      window.open(url, '_blank');
    },
    showDetails(server) {
      this.currentServer = server;
      this.dialogVisible = true;
    },
    handleLoginSuccess() {
      this.loggedIn = true;
    }
  }
};
</script>

<style scoped>
body, html {
  height: 100%;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  min-height: 100%;
  background: url('/public/aurora.jpg') no-repeat center center fixed;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.el-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.el-header {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  width: 100%;
  padding: 10px 0;
}

.el-main {
  background: rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.el-card {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  margin-bottom: 20px;
}

.el-button {
  margin-top: 10px;
}

.el-dialog__body {
  color: black;
}

@media (max-width: 768px) {
  .el-main {
    padding: 10px;
  }
}
</style>
