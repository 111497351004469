<template>
    <div class="login-container">
      <el-card>
        <h2>请输入密码</h2>
        <el-form :model="form" @submit.prevent="handleSubmit">
          <el-form-item>
            <el-input
              v-model="form.password"
              type="password"
              placeholder="密码"
            />
          </el-form-item>
          <el-form-item>
            <div class="button-container">
              <el-button type="primary" @click="handleSubmit">登录</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'LoginForm',
    data() {
      return {
        form: {
          password: ''
        }
      };
    },
    methods: {
      async handleSubmit() {
        try {
         
          const response = await axios.post('https://www.qwq.vin/api/server/pwd.php', { password: this.form.password });
          console.log('Response:', response.data);
          if (response.data.success) {
            this.$emit('loginSuccess');
          } else {
            this.$message.error('密码错误');
          }
        } catch (error) {
          console.error('Error verifying password:', error);
          this.$message.error('验证密码时出错');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .el-card {
    width: 300px;
    padding: 20px;
    text-align: center;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  </style>
  